<script setup lang="ts">
import { PhArrowLeft } from '@phosphor-icons/vue';
import { computed } from 'vue';

import { useSiteVariables } from '@/composables/useSiteVariables';

import baseMokButton from './base-mok-button.vue';

const { logoUrl } = useSiteVariables();

type Props = {
  backHref?: string;
  emitBackEvent?: boolean
  title: string;
  subtitle?: string;
};

const props = withDefaults(defineProps<Props>(), {
  backHref: undefined,
  emitBackEvent: false,
  subtitle: undefined,
});

const emit = defineEmits<{(event: 'back'): void;
}>();

const showBack = computed(() => props.backHref || props.emitBackEvent);

function handleBackClick() {
  if (props.emitBackEvent) emit('back');
}

</script>

<template>
  <div class="flex flex-col gap-y-4 pb-8 text-center">
    <div class="absolute left-6 top-6 mb-4 sm:left-20 sm:top-16 sm:mb-0">
      <base-mok-button
        v-if="showBack"
        data-testid="back-button"
        :href="backHref"
        variant="link"
        size="large"
        type="button"
        :label="$t('common.back')"
        :icon="PhArrowLeft"
        @click="handleBackClick"
      />
    </div>
    <img
      :src="logoUrl"
      class="mx-auto h-10 sm:h-16"
    >
    <h1 class="text-base font-semibold text-slate-900 sm:text-2xl">
      {{ title }}
    </h1>
    <p
      v-if="subtitle"
      class="pt-2 text-center text-xs text-slate-900 sm:text-sm"
    >
      {{ subtitle }}
    </p>
  </div>
</template>
