import type { CustomLocale, Language } from '@/types/locales';

type SiteVariables = {
  locale: Language;
  logoUrl: string;
  recaptchaSiteKey?: string;
  customLocales: CustomLocale[];
}

export interface SponsorAppWindow extends Window {
  siteVariables: SiteVariables;
}

export function useSiteVariables() {
  const siteVariables = (window as SponsorAppWindow & typeof globalThis).siteVariables;
  const locale = siteVariables.locale;

  return {
    ...siteVariables,
    locale,
  };
}
