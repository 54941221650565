<script setup lang="ts">
import axios, { type AxiosError } from 'axios';
import { computed, ref, onMounted, watch, provide } from 'vue';
import { useI18n } from 'vue-i18n';

import { eventsApi } from '@/api/events';
import { usersApi, type UserForm } from '@/api/users';
import { useRecaptcha } from '@/composables/useRecaptcha';
import type { CountryCode } from '@/types/country-code';
import type { LegalDocument } from '@/types/legal-document';
import type { RegistrationGuide } from '@/types/registration-guide';
import type { UserSessionMode } from '@/types/user-session-mode';

import UserSessionLoginForm from './user-session-login-form.vue';
import UserSessionRegistrationForm from './user-session-registration-form.vue';
import UserSessionResetPasswordForm from './user-session-reset-password-form.vue';
import UserSessionUpdatePasswordForm from './user-session-update-password-form.vue';

type Props = {
  mode: UserSessionMode;
  registrationToken?: string;
  redirect?: string;
  referer?: string;
  countryCode: CountryCode;
  countryNationalIdName: string;
  countryFlagIcon?: string;
  mobilePhoneCodePrefix: string;
  mobilePhoneNationalPattern: string;
  passwordRecoveryEnabled?: boolean;
  hideNationalId?: boolean;
  webAppBaseUrl?: string;
  needsDocumentIdentifierInput?: boolean;
  registrationGuide?: RegistrationGuide;
  documentIdentifierKind?: string;
  legalDocuments: LegalDocument[];
  hideMobilePhoneNumber?: boolean;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    registrationToken: undefined,
    redirect: '/',
    referer: '/',
    countryFlagIcon: undefined,
    passwordRecoveryEnabled: false,
    hideNationalId: false,
    webAppBaseUrl: '',
    needsDocumentIdentifierInput: false,
    documentIdentifierKind: '',
    registrationGuide: undefined,
    hideMobilePhoneNumber: false,
  },
);

if (props.registrationGuide) {
  provide('registrationGuide', props.registrationGuide);
}

const { t } = useI18n();

const formError = ref('');
const formSuccess = ref(false);
const formLoading = ref(false);
const formErrorKeyPath = ref('userSession.formError.register');

const internalMode = ref(props.mode);
const { loadRecaptcha } = useRecaptcha();

const EVENT_ALLOWED_MODES = ['login', 'register'];
function sendEvent(eventName: UserSessionMode) {
  if (!EVENT_ALLOWED_MODES.includes(eventName)) return;

  eventsApi.send(`${eventName}_initiate`);
}

watch(() => internalMode.value, async () => {
  sendEvent(internalMode.value);
  formError.value = '';
});

onMounted(async () => {
  await loadRecaptcha();
  sendEvent(props.mode);
});

function toggleMode(mode: UserSessionMode | undefined = undefined) {
  if (mode) {
    internalMode.value = mode;
  } else {
    internalMode.value = internalMode.value === 'login' ? 'register' : 'login';
  }
}

function submitLogin(values: { email: string; password: string; }) {
  return usersApi.login(values.email, values.password);
}

function submitRegister(values: UserForm) {
  if (props.registrationToken) {
    values.registrationToken = props.registrationToken;
  }

  return usersApi.register(values);
}

function submitResetPassword(values: { email: string; }) {
  return usersApi.resetPassword(values.email);
}

function submitUpdatePassword(values: UserForm) {
  return usersApi.updatePassword(values);
}

// eslint-disable-next-line max-statements
async function submitForm(values: UserForm) {
  formLoading.value = true;
  type Submit = (val: UserForm) => Promise<unknown>;

  const submit = {
    login: submitLogin,
    register: submitRegister,
    resetPassword: submitResetPassword,
    updatePassword: submitUpdatePassword,
  }[internalMode.value] as Submit;
  try {
    await submit(values);
    formError.value = '';
    formSuccess.value = true;
  } catch (error) {
    formError.value = t('userSession.formError', { action: t(`userSession.${internalMode.value}`) });
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<string>;

      const legalDocumentsError = axiosError.response?.data.includes('LegalDocumentNotAccepted');
      formErrorKeyPath.value = legalDocumentsError ?
        'userSession.formError.legalDocuments' :
        'userSession.formError.register';
    }
  } finally {
    formLoading.value = false;
  }
}

const MODES_WITHOUT_REDIRECT = ['resetPassword'];
const currentModeShouldRedirect = computed(() => !MODES_WITHOUT_REDIRECT.includes(internalMode.value));

watch(formSuccess, (newValue) => {
  if (!newValue) return;
  if (!currentModeShouldRedirect.value) return;

  window.location.href = props.redirect;
});
</script>

<template>
  <div class="relative flex flex-1 justify-center py-16">
    <div class="w-full max-w-xl bg-white p-8 sm:mx-auto sm:rounded-2xl sm:py-28">
      <user-session-login-form
        v-if="internalMode === 'login'"
        :referer-url="referer"
        :password-recovery-enabled="props.passwordRecoveryEnabled"
        @toggle-mode="toggleMode"
        @submit="submitForm"
      />
      <user-session-registration-form
        v-else-if="internalMode === 'register'"
        :country-code="props.countryCode"
        :country-national-id-name="props.countryNationalIdName"
        :country-flag-icon="props.countryFlagIcon"
        :mobile-phone-code-prefix="props.mobilePhoneCodePrefix"
        :mobile-phone-national-pattern="props.mobilePhoneNationalPattern"
        :hide-national-id="props.hideNationalId"
        :web-app-base-url="props.webAppBaseUrl"
        :needs-document-identifier-input="props.needsDocumentIdentifierInput"
        :document-identifier-kind="props.documentIdentifierKind"
        :legal-documents="props.legalDocuments"
        :hide-mobile-phone-number="props.hideMobilePhoneNumber"
        @toggle-mode="toggleMode"
        @submit="submitForm"
      />
      <user-session-reset-password-form
        v-else-if="internalMode === 'resetPassword'"
        :referer-url="referer"
        @toggle-mode="toggleMode"
        @submit="submitForm"
      />
      <user-session-update-password-form
        v-else-if="internalMode === 'updatePassword'"
        @toggle-mode="toggleMode"
        @submit="submitForm"
      />
      <div
        v-if="formError"
        class="relative mt-4 rounded border border-error-200 bg-error-50 px-4 py-3 text-error-900"
      >
        <i18n-t
          v-if="internalMode === 'register'"
          :keypath="formErrorKeyPath"
          tag="span"
          class="block sm:inline"
        >
          <template
            #recoverPassword
          >
            <button
              class="underline"
              @click="internalMode = 'resetPassword'"
            >
              {{ $t('userSession.formError.recoverPassword') }}
            </button>
          </template>
        </i18n-t>
        <i18n-t
          v-else
          keypath="userSession.formError.generic"
          tag="span"
          class="block sm:inline"
        >
          <template #action>
            {{ $t(`userSession.${internalMode}`) }}
          </template>
        </i18n-t>
      </div>
      <div
        v-else-if="formSuccess && !currentModeShouldRedirect"
        class="relative mt-4 rounded border border-primary px-4 py-3"
      >
        {{ t(`userSession.${internalMode}Page.submitNotice`) }}
      </div>
    </div>
  </div>
</template>
