import { useSiteVariables } from '@/composables/useSiteVariables';
import replaceLocales from '@/utils/replace-locales';

import enUSLocale from './enUS.json';
import esCLLocale from './esCL.json';
import esEsLocale from './esES.json';
import frFRLocale from './frFR.json';
import ptBRLocale from './ptBR.json';
import ptPTLocale from './ptPT.json';

const { locale: siteLocale, customLocales } = useSiteVariables();
const locale = siteLocale || 'esCL';

const messages = {
  'en-US': enUSLocale,
  'es-CL': esCLLocale,
  'es-ES': esEsLocale,
  'fr-FR': frFRLocale,
  'pt-BR': ptBRLocale,
  'pt-PT': ptPTLocale,
};

const messagesOverride = replaceLocales(messages, customLocales, locale);

export const locales = { messages: messagesOverride };
