<script setup lang="ts">
import { PhFileText } from '@phosphor-icons/vue';
import { camelize } from 'humps';
import { Form as VForm } from 'vee-validate';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { object, string, array } from 'yup';

import type { UserForm } from '@/api/users';
import type { CountryCode } from '@/types/country-code';
import type { LegalDocument } from '@/types/legal-document';
import type { UserSessionMode } from '@/types/user-session-mode';
import { dniUtils } from '@/utils/dni-utils';
import { getPasswordValidationSchema } from '@/utils/password-utils';

import baseMokButton from './base-mok-button.vue';
import BaseMokCheckboxInput from './base-mok-checkbox-input.vue';
import baseMokInput from './base-mok-input.vue';
import BaseMokModalDocument from './base-mok-modal-document.vue';
import BasePasswordInput from './base-password-input.vue';
import basePhoneInput from './base-phone-input.vue';
import DocumentIdentifierInput from './document-identifier-input.vue';
import UserSessionHeader from './user-session-header.vue';

const { t } = useI18n();

export type Props = {
  countryCode: CountryCode;
  countryNationalIdName: string;
  countryFlagIcon?: string;
  mobilePhoneCodePrefix: string;
  mobilePhoneNationalPattern: string;
  hideNationalId: boolean;
  webAppBaseUrl: string;
  needsDocumentIdentifierInput?: boolean;
  documentIdentifierKind: string;
  legalDocuments: LegalDocument[];
  hideMobilePhoneNumber: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  countryFlagIcon: undefined,
  legalDocuments: () => [] as LegalDocument[],
});

const form = ref(null);
const formValues = ref<UserForm>({
  firstName: '',
  lastName: '',
  email: '',
  acceptedLegalDocuments: [],
});

const { format: formatDni, validate: validateDni } = dniUtils[props.countryCode] || {};
const legalDocumentSelected = ref<LegalDocument>({
  id: -1,
  name: '',
  contentBody: '',
});
const openModal = ref<boolean>(false);

function dniValidateFunction(value: string) {
  return validateDni ? validateDni(value) : true;
}

function dniFormatFunction(value: string) {
  return formatDni ? formatDni(value) : value;
}

const emit = defineEmits<{(event: 'toggle-mode', value?: UserSessionMode): void;
  (event: 'submit', values: UserForm): void;
}>();

const firstFormValidationSchema =
  object().shape({
    firstName: string().required(),
    lastName: string().required(),
    email: string().required().email(),
    mobilePhoneNumber: props.hideMobilePhoneNumber ?
      string() : string().required().matches(new RegExp(`${props.mobilePhoneNationalPattern}$`)),
    nationalId: props.hideNationalId ? string() : string().required().test(dniValidateFunction),
    documentIdentifier: props.needsDocumentIdentifierInput ? string().required() : string(),
  });

const secondFormValidationSchema = computed(() => getPasswordValidationSchema().value.concat(
  object().shape({
    acceptedLegalDocuments: props.legalDocuments.length ? array()
      .required()
      .length(props.legalDocuments.length)
      .test('same-elements', (value) => {
        if (!value) return false;
        const sortedAccepted = [...value].sort();
        const sortedLegalDocs = props.legalDocuments?.map(({ id }) => id).sort();

        return JSON.stringify(sortedAccepted) === JSON.stringify(sortedLegalDocs);
      }) : array(),
  }),
));

const step = ref(0);
const LAST_STEP = 1;
function nextStep() {
  step.value += 1;
}

function submitForm() {
  emit('submit', formValues.value as UserForm);
}

function validateForm(values: UserForm) {
  formValues.value = { ...formValues.value, ...values };
  if (step.value === LAST_STEP) {
    submitForm();
  } else {
    nextStep();
  }
}

const camelizedDocumentIdentifierKind = camelize(props.documentIdentifierKind);

function handleClick(legalDocument: LegalDocument) {
  legalDocumentSelected.value = legalDocument;
  openModal.value = true;
}

function closeModal() {
  openModal.value = false;
}
</script>

<template>
  <v-form
    v-if="step !== LAST_STEP"
    ref="form"
    v-slot="{ meta: metaFirstForm }"
    :validation-schema="firstFormValidationSchema"
    class="flex flex-col gap-y-4"
    @submit="validateForm"
  >
    <user-session-header
      :title="t('userSession.registerPage.firstTitle')"
      emit-back-event
      @back="emit('toggle-mode', 'login')"
    />
    <div class="flex flex-col gap-4 sm:flex-row">
      <base-mok-input
        :label="t('userSession.firstName')"
        :placeholder="t('userSession.firstName')"
        name="firstName"
      />
      <base-mok-input
        :label="t('userSession.lastName')"
        :placeholder="t('userSession.lastName')"
        name="lastName"
      />
    </div>
    <base-mok-input
      v-if="!hideNationalId"
      :label="countryNationalIdName"
      :placeholder="countryNationalIdName"
      name="nationalId"
      @input="($event.target as HTMLInputElement).value = dniFormatFunction($event.target.value)"
    />
    <document-identifier-input
      v-if="props.needsDocumentIdentifierInput"
      name="documentIdentifier"
      :label="t(`userSession.documentIdentifierKinds.${camelizedDocumentIdentifierKind}`)"
      :placeholder="t(`userSession.documentIdentifierKinds.${camelizedDocumentIdentifierKind}`)"
    />
    <base-phone-input
      v-if="!hideMobilePhoneNumber"
      :label="t('userSession.mobilePhoneNumber')"
      name="mobilePhoneNumber"
      :prefix="props.mobilePhoneCodePrefix"
      :flag="countryFlagIcon"
    />
    <base-mok-input
      :label="t('userSession.email')"
      :placeholder="t('userSession.email')"
      autocomplete="email"
      name="email"
    />
    <div class="flex flex-col gap-3">
      <base-mok-button
        class="mt-4 w-full self-center"
        type="submit"
        variant="primary"
        :label="t('userSession.next')"
        :disabled="!metaFirstForm.valid"
      />
      <i18n-t
        keypath="userSession.haveAccount"
        tag="div"
        class="text-right text-sm text-slate-900"
      >
        <template #login>
          <button
            type="button"
            class="font-medium text-primary underline underline-offset-2"
            @click="emit('toggle-mode', 'login')"
          >
            {{ t('userSession.login') }}
          </button>
        </template>
      </i18n-t>
    </div>
  </v-form>
  <v-form
    v-else
    ref="form"
    v-slot="{ meta: metaSecondForm }"
    :validation-schema="secondFormValidationSchema"
    class="flex flex-col gap-y-4"
    @submit="validateForm"
  >
    <base-mok-modal-document
      :is-open="openModal"
      :title="legalDocumentSelected?.name"
      :content="legalDocumentSelected?.contentBody"
      :icon="PhFileText"
      :hide-arrow="true"
      @cancel="closeModal"
      @close="closeModal"
      @confirm="closeModal"
    />
    <user-session-header
      :title="t('userSession.registerPage.secondTitle')"
      emit-back-event
      @back="emit('toggle-mode', 'login')"
    />
    <base-password-input
      v-model="formValues.password"
      :label="t('userSession.password')"
      :placeholder="t('userSession.password')"
      type="password"
      autocomplete="new-password"
      name="password"
      needs-validation
    />
    <base-password-input
      v-model="formValues.passwordConfirmation"
      :label="t('userSession.passwordConfirmation')"
      :placeholder="t('userSession.passwordConfirmation')"
      type="password"
      autocomplete="new-password"
      name="passwordConfirmation"
    />
    <div
      v-if="legalDocuments?.length"
      class="flex flex-col gap-4"
    >
      <base-mok-checkbox-input
        v-for="(legalDocument) in legalDocuments"
        :key="legalDocument.id"
        v-model="formValues.acceptedLegalDocuments"
        :value="legalDocument.id"
        name="acceptedLegalDocuments"
        size="small"
        :data-testid="`${legalDocument.name}-legal-document-input`"
      >
        <p class="text-xs	">
          {{ t('userSession.acceptLegalDocument') }}
          <a
            class="underline"
            @click.prevent="() => handleClick(legalDocument)"
          >
            {{ legalDocument.name }}
          </a>
        </p>
      </base-mok-checkbox-input>
    </div>
    <base-mok-button
      class="mt-4 w-full self-center"
      type="submit"
      variant="primary"
      :label="t('userSession.register')"
      :disabled="!metaSecondForm.valid"
    />
  </v-form>
</template>

