import { api } from './index';

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone?: string;
  nationalId?: string;
  documentIdentifier?: string;
}

export interface UserForm extends User {
  passwordConfirmation?: string;
  password?: string;
  registrationToken?: string;
  resetPasswordToken?: string;
  acceptedLegalDocuments: string[];
}

function login(email: string, password: string) {
  const path = '/users/sign_in';

  return api({
    method: 'post',
    url: path,
    data: {
      user: { email, password },
    },
  });
}

function register(user: UserForm) {
  const path = '/users';

  return api({
    method: 'post',
    url: path,
    data: { user },
  });
}

function resetPassword(email: string) {
  const path = '/users/password';

  return api({
    method: 'post',
    url: path,
    data: { user: { email } },
  });
}

function updatePassword(user: UserForm) {
  const path = '/users/password';

  return api({
    method: 'patch',
    url: path,
    data: { user },
  });
}

export const usersApi = { login, register, resetPassword, updatePassword };
