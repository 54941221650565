<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogPanel,
} from '@headlessui/vue';
import { twMerge } from 'tailwind-merge';
import { computed, ref } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

const mobileModalPosition = {
  bottom: 'bottom-0 md:inset-0',
  center: 'inset-0 m-4',
};

const radiusByPosition = {
  bottom: 'rounded-t-2xl md:rounded-2xl',
  center: 'rounded-2xl',
};

const modalBlur = {
  normal: 'backdrop-blur',
  lg: 'backdrop-blur-lg',
  xl: 'backdrop-blur-xl',
  '2xl': 'backdrop-blur-2xl',
};

export type Props = {
  isOpen: boolean;
  mobilePosition?: keyof typeof mobileModalPosition;
  blurOverlay?: keyof typeof modalBlur;
  theme?: 'light' | 'dark';
}

const props = withDefaults(defineProps<Props>(), {
  mobilePosition: 'center',
  blurOverlay: undefined,
  theme: 'dark',
});
defineEmits<{(e: 'close'): void }>();

const initialFocusRef = ref<HTMLElement>();
const blurOverlayActive = computed(() => !!props.blurOverlay);
const overlayBgColor = computed(() => (props.theme === 'dark' ? 'bg-black/60' : 'bg-white/60'));

const attrsWithoutClass = useAttrsWithoutClass();
function setInitialFocusRef(element: HTMLElement) {
  initialFocusRef.value = element;

  return initialFocusRef;
}
</script>

<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-40"
      :initial-focus="initialFocusRef"
      @close="$emit('close')"
    >
      <DialogOverlay
        class="fixed inset-0"
        :class="[{[modalBlur[blurOverlay!]]: blurOverlayActive}, overlayBgColor]"
      />
      <div
        class="fixed inset-0 flex h-screen items-center justify-center text-center"
      >
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            :class="twMerge(
              'fixed flex flex-col overflow-y-auto bg-white text-left align-middle shadow-xl transition-all',
              'md:self-center md:justify-self-center max-h-full max-w-max md:max-w-xl',
              mobileModalPosition[mobilePosition],
              radiusByPosition[mobilePosition],
              $attrs.class as string,
            )"
            v-bind="attrsWithoutClass"
          >
            <slot
              :set-initial-focus-ref="
                setInitialFocusRef"
            />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
